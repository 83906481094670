import GolfErpAPI from "@/api/v2/GolfErpAPI";

export const ROOT_PATH = '/stock-product-mng';

class StockProductMng {
    /**상품 입고반품 리스트 조회*/
    async getProductWrhousng(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/list` ,{params} );
        return data;
    }
    /**상품 입고반품 상세 조회*/
    async getProductWrhousngDtl(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/detail` ,{params} );
        return data;
    }
    /**상품 입고반품 상세 입고/반품 아이템 조회*/
    async getProductWrhousngProduct(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/product` ,{params} );
        return data;
    }
    /**상품 입고반품 생성*/
    async postProductWrhousngProduct(params) {
        const {data} = await GolfErpAPI.http.post(`${ROOT_PATH}/product` ,params);
        return data;
    }
    /**상품 입고반품 수정*/
    async patchProductWrhousngProduct(params) {
        const {data} = await GolfErpAPI.http.patch(`${ROOT_PATH}/product` ,params);
        return data;
    }
    /**상품 입고반품 삭제*/
    async deleteProductWrhousngProduct(params) {
        const {data} = await GolfErpAPI.http.delete(`${ROOT_PATH}/product` ,{params} );
        return data;
    }

    /**상품 출고 리스트 조회*/
    async getProductDlivy(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/dlivy/list` ,{params} );
        return data;
    }

    /**상품 출고 상세 조회*/
    async getProductDlivyDtl(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/dlivy/detail` ,{params} );
        return data;
    }
    /**상품 출고반품 상세 출고/폐기/이동 아이템 조회*/
    async getProductDlivyProduct(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/dlivy/product` ,{params} );
        return data;
    }
    /**상품 출고반품 생성*/
    async postProductDlivyProduct(params) {
        const {data} = await GolfErpAPI.http.post(`${ROOT_PATH}/dlivy/product` ,params);
        return data;
    }
    /**상품 출고반품 수정*/
    async patchProductDlivyProduct(params) {
        const {data} = await GolfErpAPI.http.patch(`${ROOT_PATH}/dlivy/product` ,params);
        return data;
    }
    /**상품 출고반품 삭제*/
    async deleteProductDlivyProduct(params) {
        const {data} = await GolfErpAPI.http.delete(`${ROOT_PATH}/dlivy/product` ,{params} );
        return data;
    }

}

export default new StockProductMng();